import { combineReducers } from 'redux';
import { reducer as account } from './action/account';
import { reducer as layout } from './action/layout';
import { reducer as event } from './action/event';
import { reducer as survey } from './action/survey';
import { reducer as common } from './action/common';
import { integrations } from './integrations/reducer';
import { insight } from './insight/reducer';

export default combineReducers({
    account,
    layout,
    event,
    survey,
    common,
    integrations,
    insight,
});
