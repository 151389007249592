const UPDATE_OFFICES = 'COMMON_UPDATE_OFFICES';
export const createUpdateOfficeAction = (value = null) => ({
    type: UPDATE_OFFICES,
    offices: value,
});

const initState = {
    users: [],
    offices: [],
};
export function reducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_OFFICES:
            if (action.offices && action.offices.length > 0) {
                return { ...state, offices: action.offices };
            }
            return state;
        default:
            return state;
    }
}
