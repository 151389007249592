import { handleActions } from 'redux-actions';
import { updateFilter } from './action';

const initState = {
    filter: {
        location: null,
        role: null,
        department: null,
        gender: null,
    },
};

export const insight = handleActions(
    {
        [updateFilter]: (state, { payload }) => ({
            ...state,
            filter: {
                ...state.filter,
                ...payload,
            },
        }),
    },
    initState,
);
